import { TFunction } from 'next-i18next';

/**
 * Print number as formatted currency string.
 * @param number number
 * @param currency currency code
 * @param currencyDisplay currency symbol display
 * @returns formatted currency string
 */
export const printCurrency = (
  number: number,
  currency: 'idr' | 'usd',
  currencyDisplay: 'code' | 'symbol' = 'code',
) => {
  let locale = 'en-US';
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
    currencyDisplay,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  switch (currency) {
    case 'idr':
      locale = 'id-ID';
      options.minimumFractionDigits = 0;
      options.maximumFractionDigits = 0;
      break;
    default:
      break;
  }

  return number.toLocaleString(locale, options);
};

/**
 * Convert current number to default IDR format
 * @param currency currency value
 */
export const toIDR = (currency: number) => {
  let rupiah = '';
  const numrev = currency.toString().split('').reverse().join('');
  for (let i = 0; i < numrev.length; i += 1) if (i % 3 === 0) rupiah += `${numrev.substr(i, 3)}.`;
  return `Rp. ${rupiah.split('', rupiah.length - 1).reverse().join('')}`;
};

/**
 * Convert regular string to base64 string.
 * @param input Input text that want to convert into base64
 */
export const btoa = (input: string) => Buffer.from(input).toString('base64');

/**
  * Convert base64 string to regular string.
  * @param input Input base64 text that want to convert into regular string
  */
export const atob = (input: string) => Buffer.from(input, 'base64').toString();

/**
 * Parsing minutes into string format ([X] Hours [X] minutes)
 * @param minute total minutes in number
 */
export const minuteParsing = (minute: number, t: TFunction) => {
  if (minute < 60) return `${minute} ${t('minutes')}`;

  const hour = Math.floor(minute / 60);
  const mins = minute % 60;
  return `${hour} ${t('hours')}${mins ? ` ${mins} ${t('minutes')}` : ''}`;
};

import React, { FunctionComponent } from 'react';
import { FiBook, FiStar } from 'react-icons/fi';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

import { printCurrency } from '@helpers/string';

interface Props {
  title: string;
  image: string;
  tags: string[];
  modules: number;
  level: string;
  price: number;
  originalPrice: number;
  courseLink: string;
}

const CourseItem: FunctionComponent<Props> = (props) => {
  const {
    title,
    image,
    tags,
    modules,
    level,
    price,
    originalPrice,
    courseLink,
  } = props;

  const { t } = useTranslation('course');

  let discount = 0;
  if (price < originalPrice) {
    discount = Math.round(((originalPrice - price) / originalPrice) * 100);
  }

  return (
    <Link href={courseLink} passHref>
      <a className="rounded-md p-5 bg-white transition duration-300 ease-in-out hover:-translate-y-2 cursor-pointer">
        <div
          className="min-h-[150px] w-full bg-no-repeat bg-cover bg-center rounded-lg"
          style={{ backgroundImage: `url('${image}')` }}
        />
        <h5 className="mt-2 mb-3 text-xl font-medium">{title}</h5>
        <div className="flex flex-wrap gap-1.5">
          {tags.map((tag) => (
            <div
              key={tag}
              className="p-3 py-1.5 font-medium text-sm rounded-md bg-blue-lightest"
            >
              {tag}
            </div>
          ))}
        </div>
        <div className="flex flex-wrap gap-1.5 mt-1.5">
          <div className="p-3 py-1.5 font-medium text-sm rounded-md bg-gray-100 text-blue flex items-center">
            <FiBook className="mr-1" />
            {` ${modules} ${t('modules')}`}
          </div>
          <div className="p-3 py-1.5 font-medium text-sm rounded-md bg-gray-100 text-blue flex items-center">
            <FiStar className="mr-1" />
            {` Level ${t(level)}`}
          </div>
        </div>
        <div className="flex items-center mt-2 gap-1">
          {discount > 0 && (
            <>
              <p className="text-gray-400 font-medium text-sm line-through">{printCurrency(originalPrice, 'idr', 'symbol')}</p>
              <span className="p-1 rounded-sm bg-magenta text-white text-[10px] font-medium leading-none">
                {`-${discount}%`}
              </span>
            </>
          )}
        </div>
        <h6 className="text-md font-medium text-magenta">
          {price ? printCurrency(price, 'idr', 'symbol') : `${t('free')}!`}
        </h6>
      </a>
    </Link>
  );
};

export default CourseItem;

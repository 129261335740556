import React, {
  useEffect,
} from 'react';
import {
  GetServerSideProps,
  NextPage,
} from 'next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { FiChevronRight } from 'react-icons/fi';

import Layout from '@layout';
import CourseItem from '@component/CourseItem';
import { Course as CourseType, LinkShortener } from '@type';
import { btoa } from '@helpers/string';
import { getCourseList } from '@resources/course';
import { getLinkShorteners } from '@resources/linkShortener';

interface Props {
  courses: CourseType[];
  challenges: LinkShortener[];
}

const Course: NextPage<Props> = (props: Props) => {
  const { t } = useTranslation('course');
  const { asPath } = useRouter();
  const { courses, challenges } = props;

  // Smooth scrolling anchor tag
  useEffect(() => {
    const tag = asPath.substring(2);
    const element = document.getElementById(`${tag}-scr`);
    if (element) {
      const yOffset = ['learn', 'challenge'].includes(tag) ? -150 : -100;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [asPath]);

  return (
    <Layout course pageTitle="Algobash Developer - # 1 Coding Test in Indonesia">
      <section id="home-scr" className="bg-blue-semilight lg:min-h-[500px] pb-10 pt-36 justify-items-center items-center">
        <div className="container container-padding mx-auto mb-4">
          <div className="flex flex-col md:flex-row justify-items-center items-center">
            <div className="order-last lg:order-none md:text-left text-center xl:flex-none xl:max-w-[480px] flex-1">
              <h1 className="font-itim text-white text-3xl lg:text-5xl font-normal lg:ml-0 md:ml-5">
                {t('title1')}
                <br />
                <span className="font-itim text-lime-light font-normal">{t('title2')}</span>
              </h1>
            </div>
            <div className="flex-1 justify-center">
              <img className="ml-auto" src="/images/course-home.png" alt="course-home.png" />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center max-w-[800px] mx-auto">
          <div className="m-2 my-1 py-2 px-3 rounded-md bg-white text-black font-medium whitespace-nowrap">{t('pill1')}</div>
          <div className="m-2 my-1 py-2 px-3 rounded-md bg-white text-black font-medium whitespace-nowrap">{t('pill2')}</div>
          <div className="m-2 my-1 py-2 px-3 rounded-md bg-white text-black font-medium whitespace-nowrap">{t('pill3')}</div>
          <div className="m-2 my-1 py-2 px-3 rounded-md bg-white text-black font-medium whitespace-nowrap">{t('pill4')}</div>
          <div className="m-2 my-1 py-2 px-3 rounded-md bg-white text-black font-medium whitespace-nowrap">{t('pill5')}</div>
          <div className="m-2 my-1 py-2 px-3 rounded-md bg-white text-black font-medium whitespace-nowrap">{t('pill6')}</div>
        </div>
        <br />
        <Link href="/" passHref>
          <button className="bg-lime-light rounded-full block mx-auto" type="button">
            {t('start')}
          </button>
        </Link>
      </section>
      <section className="bg-gray-100" hidden>
        <div className="h-full p-5 lg:pb-10 xl:px-24 rounded-b-medium bg-white">
          <div className="font-itim text-blue-semilight font-medium text-xl text-center mb-3 mt-1">
            {t('trusted')}
          </div>
          <div className="flex flex-wrap justify-between items-center content-center w-full">
            <img className="mx-auto" src="/images/icon-xtremax.png" alt="icon-xtremax" />
            <img className="mx-auto" src="/images/icon-lingotalk.png" alt="icon-lingotalk" />
            <img className="mx-auto" src="/images/icon-traveloka.png" alt="icon-cbn" />
            <img className="mx-auto" src="/images/icon-sirclo.png" alt="icon-efishery" />
            <img className="mx-auto" src="/images/icon-gredu.png" alt="icon-feedloop" />
            <img className="mx-auto" src="/images/icon-cicil.png" alt="icon-cicil" />
          </div>
        </div>
      </section>
      <section className="w-full bg-gray-100 pt-16 pb-8">
        <div className="container container-padding mx-auto">
          <div id="learn-scr" className="flex justify-between flex-col md:flex-row md:items-center items-start">
            <h4 className="font-itim text-2xl">{t('courseTitle')}</h4>
            <Link href="/courses">
              <a className="text-blue inline-flex items-center font-medium mt-2 md:mt-0">
                {`${t('viewMore')}  `}
                <FiChevronRight
                  className="ml-2 stroke-3"
                />
              </a>
            </Link>
          </div>
          <br />
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-6 items-start">
            {courses.map((course) => {
              const id = btoa(course.id.toString());
              const slug = course.name.replaceAll(' ', '-').toLowerCase();

              return (
                <CourseItem
                  key={`course-${course.id}`}
                  image={course.image}
                  title={course.name}
                  tags={course.tags}
                  modules={course.examList.length}
                  level={course.level}
                  price={course.fee}
                  originalPrice={course.originalFee}
                  courseLink={`/courses/${id}/${slug}`}
                />
              );
            })}
          </div>
          <br />
          <br />
          <br />
          <div id="challenge-scr" className="flex justify-between flex-col md:flex-row md:items-center items-start">
            <h4 className="font-itim text-2xl">{t('challengeTitle')}</h4>
          </div>
          <br />
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-6">
            {challenges.map((item) => (
              <Link
                key={item.id}
                href={item.link}
                passHref
              >
                <a className="rounded-md p-5 bg-white transition duration-300 ease-in-out hover:-translate-y-2 cursor-pointer">
                  <img
                    className="m-auto"
                    src={item.image}
                    alt={item.name}
                  />
                </a>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps<Props> = async ({ locale = 'en' }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'course'])),
    courses: (await getCourseList({ itemsPerPage: 6 }))?.data?.items || [],
    challenges: (await getLinkShorteners({
      itemsPerPage: 3,
      filters: 'type==challenges|ongoing==1',
      sorts: '-id',
    }))?.data?.items || [],
  },
});

export default Course;
